<template>
  <div id="generalOrderCell">
    <el-row v-if="orderItem" class="generalOrderCellItem">
      <el-row class="cellOne">
        <el-col :span="3">
          <span>订单编号：{{ orderItem.orderId ? orderItem.orderId : '--' }}</span>
        </el-col>
        <el-col :span="6">
          <span>下单时间：{{ orderItem.createTime | DateFormate }}</span>
        </el-col>
        <el-col
          :span="6"
          class="espli"
          v-if="(orderItem.hangFirmId==fatherFrimId&&userType === 'buyer')||(orderItem.pickFirmId==fatherFrimId&&userType === 'seller')"
        >
          <span>摘单交易商：{{ orderItem.sellEnterpriseName ? orderItem.sellEnterpriseName : '--'}}</span>
        </el-col>
        <el-col
          :span="6"
          class="espli"
          v-if="(orderItem.hangFirmId==fatherFrimId&&userType === 'buyer')||(orderItem.pickFirmId==fatherFrimId&&userType === 'seller')"
        >
          <span>挂单交易商：{{ orderItem.buyEnterpriseName ? orderItem.buyEnterpriseName : '--' }}</span>
        </el-col>
        <el-col
          :span="6"
          class="espli"
          v-if="(orderItem.pickFirmId==fatherFrimId&&userType === 'buyer')||(orderItem.hangFirmId==fatherFrimId&&userType === 'seller')"
        >
          <span>摘单交易商：{{ orderItem.buyEnterpriseName ? orderItem.buyEnterpriseName : '--' }}</span>
        </el-col>
        <el-col
          :span="6"
          class="espli"
          v-if="(orderItem.pickFirmId==fatherFrimId&&userType === 'buyer')||(orderItem.hangFirmId==fatherFrimId&&userType === 'seller')"
        >
          <span>挂单交易商：{{ orderItem.sellEnterpriseName ? orderItem.sellEnterpriseName : '--' }}</span>
        </el-col>
        <el-col :span="3">
          <div class="viewDetailsButton">
            <el-button
              size="mini"
              @click="viewDetails(orderItem.orderId,orderItem.pickResourcesId,userType)"
            >查看详情</el-button>
          </div>
        </el-col>
      </el-row>
      <el-row class="cellTwo">
        <el-col :span="22">
          <el-col :span="3">
            <span>{{ orderItem.className ? orderItem.className : '--' }}</span>
          </el-col>
          <el-col :span="4">
            <span>{{ orderItem.price ? orderItem.price : '--' }}元/{{ orderItem.unit?orderItem.unit:'--' }}</span>
          </el-col>
          <el-col :span="4">
            <span>{{ orderItem.sname ? orderItem.sname : '--' }}</span>
          </el-col>
          <el-col :span="3">
            <span>{{ orderItem.arrivalStatus | arrivalStatusFormate }}</span>
          </el-col>
          <el-col :span="3">
            <span
              v-if="orderItem.pickResourcesStatus !== 'F' && orderItem.pickResourcesStatus !== 'U'"
            >{{ orderItem.openTicketStatus | ballotStatusFormate }}</span>
            <span v-else>--</span>
          </el-col>
          <el-col :span="3">
            <span
              v-if="orderItem.pickResourcesStatus !== 'F' && orderItem.pickResourcesStatus !== 'U'"
            >{{ orderItem.payStatus | payStatusFormate }}</span>
            <span v-else>--</span>
          </el-col>
          <el-col :span="4">
            <span>{{ orderItem.tradeStatus | ordersStatusFormate }}</span>
          </el-col>
        </el-col>
      </el-row>
      <el-row>
        <!-- <el-col :span="16"></el-col> -->
        <!-- <el-col :span="5" :offset="16"><span>入库状态：{{ orderItem.arrivalStatus | arrivalStatusFormate }}</span></el-col> -->
        <!-- <el-col :span="3">
          <div class="viewDetailsButton">
            <el-button size="mini" @click="viewDetails(orderItem.pickResourcesId)">查看详情</el-button>
          </div>
        </el-col>-->
      </el-row>
      <el-row class="cellThree">
        <el-col :span="1">
          <span>总计</span>
        </el-col>
        <!-- <el-col :span="2"><span>数量：{{ orderItem.tradeWeight ? orderItem.tradeWeight : '--' }}</span></el-col> -->
        <el-col :span="3">
          <span>重量：{{ orderItem.tradeWeight ? orderItem.tradeWeight : '--' }}{{orderItem.unit}}</span>
        </el-col>
        <el-col :span="10" v-if="orderType != 'bidding'">
          <span>
            总价：
            <span class="redColor">&nbsp;{{ orderItem.funds }}元</span>
          </span>&emsp;
          <span
            v-if="orderItem.bargainingPrice&&orderItem.pickResourcesStatus !== 'F' && orderItem.pickResourcesStatus !== 'U'"
          >
            议价单价：
            <span class="redColor">&nbsp;¥ {{ orderItem.bargainingPrice }}</span>&nbsp;&nbsp;
            <span>议价状态：{{orderItem.pickBargainingStatus|priceStatusFormate}}</span>
          </span>
        </el-col>
        <el-col :span="10" v-if="orderType == 'bidding'">
          <span>
            成交总价：
            <span class="redColor">&nbsp;{{ orderItem.funds }}元</span>
          </span>
        </el-col>
        <el-col
          :span="5"
          class="cellMethod"
          v-if="orderItem.payStatus === 'F' && orderItem.arrivalStatus === 'F' && userType === 'seller'"
        >
          <el-button
            type="danger"
            plain
            size="mini"
            v-if="isRongzi"
            @click="appalyFinancing(orderItem.orderId)"
          >融资</el-button>
        </el-col>
        <!-- 撤单，付首款(订单状态已挂单 付款状态待付款) -->
        <!-- 买家付首款 -->
        <!--  hangResourcesStatus  O：已挂单B：部分摘单A：全部摘单U：已撤销   ['Z','ZZ']
          this.searchForm.payStatus = ['W','ZZ']
        // tradeStatus 订单状态Z：已摘单T：已完成        tradeStatus对应orderStatus  
        // pickResourcesStatus W：待确认（30分钟时效）T：已确认F：已失效U：已取消    对应status-->
        <!--  v-if="orderItem.orderStatus == 'O' && orderItem.payStatus == 'W' && orderItem.defaultSellStatus != 'F' && orderItem.defaultBuyStatus != 'F' && userType === 'buyer'" -->
        <el-col :span="10" class="cellMethod" v-if="userType == 'buyer'&&orderItem.sourceId!='J'">
          <span
            v-if="orderItem.pickResourcesStatus === 'W' && orderItem.pickFirmId==fatherFrimId&&orderItem.payStatus !== 'W'&& (Number(hour)||Number(min)||Number(second))&&orderItem.phaseStatus!='W'"
          >确认倒计时：{{hour}} 时{{min}} 分{{second}} 秒</span>
              <span
            v-else-if="orderItem.pickResourcesStatus === 'W' && orderItem.payStatus !== 'W'&&orderItem.pickFirmId!=fatherFrimId&& (Number(hour)||Number(min)||Number(second))&&orderItem.phaseStatus!='W'"
          ></span>
          <span
            v-else-if="orderItem.pickResourcesStatus === 'W'&&orderItem.payStatus === 'ZZ'&&orderItem.hangFirmId==fatherFrimId"
          ></span>
          <span v-else-if="orderItem.payStatus === 'F'||orderItem.payStatus === 'L'"></span>
          <span v-else-if="orderItem.phaseStatus=='W'"></span>
          <span v-else>订单已失效或已取消</span>
          <!-- v-if="right.cancelOrder && gzFlag != 'G'" -->
          <!-- right.confirmOrder &&  -->
          <el-button
            v-if="right.cancelOrder&&orderItem.hangFirmId!=fatherFrimId&&(orderItem.payStatus === 'W'||orderItem.payStatus === 'ZZ')&&(orderItem.pickResourcesStatus === 'W'||orderItem.pickResourcesStatus === 'ZZ')&&orderItem.pickResourcesStatus !== 'F' && orderItem.pickResourcesStatus !== 'U'"
            plain
            type="danger"
            size="mini"
            @click="cancelOrder(orderItem.pickResourcesId)"
          >撤单</el-button>
          <el-button
            v-if="right.confirmOrder&&orderItem.type == 'BO'&&orderItem.hangFirmId!=fatherFrimId&&orderItem.payStatus === 'ZZ'&&orderItem.phaseStatus!='W'&&orderItem.pickResourcesStatus !== 'F' && orderItem.pickResourcesStatus !== 'U'"
            plain
            type="danger"
            size="mini"
            @click="showConfirmOrderDialog(orderItem)"
          >付首笔款</el-button>
          <el-button
            v-if="right.confirmOrder&&orderItem.type == 'BA'&&orderItem.hangFirmId!=fatherFrimId&&orderItem.phaseStatus!='W'&&orderItem.payStatus !== 'L'&&orderItem.pickResourcesStatus !== 'F' && orderItem.pickResourcesStatus !== 'U'"
            plain
            type="danger"
            size="mini"
            @click="showConfirmOrderDialog(orderItem)"
          >支付全款</el-button>
          <el-button
            v-if="orderItem.tradeStatus!='Z'&&orderItem.tradeStatus!='T'&&orderItem.pickResourcesStatus !== 'F'&&orderItem.pickBargainingStatus!='T' && orderItem.pickResourcesStatus !== 'U'&&(orderItem.pickFirmId == fatherFrimId||(orderItem.pickBargainingStatus === 'W'&&orderItem.hangFirmId == fatherFrimId))"
            plain
            type="danger"
            size="mini"
            @click="bargainingHangOrder(orderItem.orderId, orderItem.pickResourcesId,orderItem.hangFirmId,orderItem.price)"
          >议价</el-button>
        </el-col>
        <!-- 撤单，确认订单(订单状态已挂单 付款状态待付款) SO：卖保证金摘SA：卖仓单摘-->
        <!-- 卖家确认订单 -->
        <el-col
          :span="10"
          v-if="orderItem.tradeStatus == 'ZZ'&&(orderItem.payStatus == 'W'||orderItem.payStatus == 'ZZ')  && orderItem.defaultSellStatus != 'F' && orderItem.defaultBuyStatus != 'F' && userType === 'seller'&&orderItem.sourceId!='J'"
          class="cellMethod"
        >
          <span
            v-if="orderItem.pickResourcesStatus === 'W'&& (Number(hour)||Number(min)||Number(second))&&orderItem.pickFirmId==fatherFrimId&&orderItem.phaseStatus!='W'"
          >确认倒计时：{{hour}} 时{{min}} 分{{second}} 秒</span>
          <span v-else-if="orderItem.payStatus === 'F'||orderItem.payStatus === 'L'"></span>
          <span
            v-else-if="orderItem.pickResourcesStatus === 'W'&&orderItem.payStatus === 'ZZ'&&orderItem.hangFirmId==fatherFrimId"
          ></span>
          <span v-else-if="orderItem.phaseStatus=='W'"></span>
          <span v-else>订单已失效或已取消</span>
          <el-button
            v-if="orderItem.hangFirmId != fatherFrimId&&orderItem.pickResourcesStatus !== 'F' && orderItem.pickResourcesStatus !== 'U'"
            plain
            type="danger"
            size="mini"
            @click="cancelOrder(orderItem.pickResourcesId)"
          >撤单</el-button>
           <el-button
            v-if="right.confirmOrder&&orderItem.phaseStatus!='W'&&orderItem.hangFirmId !=fatherFrimId&&orderItem.pickResourcesStatus !== 'F' && orderItem.pickResourcesStatus !== 'U'"
            plain
            type="danger"
            size="mini"
            @click="showConfirmOrderDialog(orderItem)"
          >确认订单</el-button>
          <el-button
            v-if="orderItem.pickResourcesStatus !== 'U'&&orderItem.pickResourcesStatus !== 'F'&&((orderItem.pickBargainingStatus === 'W'&&orderItem.hangFirmId == fatherFrimId)||(orderItem.pickFirmId == fatherFrimId))"
            plain
            type="danger"
            size="mini"
            @click="bargainingHangOrder(orderItem.orderId, orderItem.pickResourcesId,orderItem.hangFirmId,orderItem.price)"
          >议价</el-button>
         
        </el-col>

        <!-- 补入库(订单状态已挂单 付款状态已付首款) -->
        <el-col
          :span="5"
          v-if="orderItem.arrivalStatus == 'F' && orderItem.payStatus == 'F' && orderItem.applyable == null && orderItem.defaultSellStatus == 'W' && orderItem.defaultBuyStatus != 'F' && userType === 'seller'"
          class="cellMethod"
        >
          <span
            v-if="orderItem.pickResourcesStatus !== 'F' && orderItem.pickResourcesStatus !== 'U'&& orderItem.defaultSellDay<=8&&orderItem.arrivalStatus!='A'"
          >入库已超期-剩余违约天数：{{ orderItem.defaultSellDay }}</span>
          <!-- <el-button 
            type="danger"
            plain
            size="mini"
            v-if="orderItem.payStatus === 'F' && orderItem.arrivalStatus === 'F'"
            @click="appalyFinancing(orderItem.orderId)"
          >融资
          </el-button>-->
        </el-col>
        <!-- <el-button :disabled="orderItem.pickResourcesStatus === 'F' || orderItem.pickResourcesStatus === 'U'" plain type="danger" size="mini" @click="goSupplementary(orderItem.pickResourcesId)">补入库</el-button> -->
        <!-- 付尾款(订单状态已摘单 付款状态已付首款) && userType === 'buyer'-->
        <el-col
          :span="10"
          v-if="orderItem.tradeStatus == 'Z' && orderItem.payStatus == 'F' && orderItem.defaultSellStatus != 'F' && userType === 'buyer'"
          class="cellMethod"
        >
          <span
            v-if="orderItem.pickResourcesStatus === 'F' || orderItem.pickResourcesStatus === 'U'"
          >订单已失效或已取消</span>
          <span
            v-if="orderItem.type != 'BA' && orderItem.pickResourcesStatus === 'T'&& orderItem.defaultBuyDay<=8&&orderItem.arrivalStatus!='A'&& orderItem.defaultBuyStatus == 'W'"
          >支付已超期-剩余违约天数：{{ orderItem.defaultBuyDay }}</span>
          <el-button
            v-if="right.payEndMoney&&orderItem.type != 'BA' && orderItem.pickResourcesStatus === 'T'&&userType === 'buyer'&&orderItem.arrivalStatus!='A'&& orderItem.defaultBuyStatus != 'F'"
            plain
            type="danger"
            size="mini"
            @click="showPayEndMoneyDialog(orderItem)"
          >付剩余款</el-button>
        </el-col>
        <!-- 开票(开票状态是未开票 付款状态是已付尾款) -->
        <el-col
          :span="10"
          v-if="orderItem.openTicketStatus == 'W' && orderItem.payStatus == 'L' && orderItem.defaultSellStatus != 'F' && orderItem.defaultBuyStatus != 'F' && userType === 'seller'"
          class="cellMethod"
        >
          <span
            v-if="orderItem.pickResourcesStatus === 'F' || orderItem.pickResourcesStatus === 'U'"
          >订单已失效或已取消</span>
          <el-button
            v-if="right.openTicket&&orderItem.pickResourcesStatus !== 'F' && orderItem.pickResourcesStatus !== 'U'"
            plain
            type="danger"
            size="mini"
            @click="openTicket(orderItem.orderId)"
          >去开票</el-button>
        </el-col>
        <!-- 开票(开票状态是未开票 付款状态是已付尾款) -->
        <el-col
          :span="10"
          v-if="orderItem.openTicketStatus == 'O' && orderItem.payStatus == 'L' && orderItem.defaultSellStatus != 'F' && orderItem.defaultBuyStatus != 'F' && userType === 'buyer'"
          class="cellMethod"
        >
          <span
            v-if="orderItem.pickResourcesStatus === 'F' || orderItem.pickResourcesStatus === 'U'"
          >订单已失效或已取消</span>
          <el-button
            v-if="right.confirmOpenTicket&&orderItem.pickResourcesStatus !== 'F' && orderItem.pickResourcesStatus !== 'U'"
            plain
            type="danger"
            size="mini"
            @click="confirmOpenTicket(orderItem.orderId)"
          >确认收票</el-button>
        </el-col>
        <el-col
          :span="10"
          v-if="orderItem.defaultSellStatus == 'F' || orderItem.defaultBuyStatus == 'F'"
          class="cellMethod"
        >
          <p>订单已违约</p>
        </el-col>

        <!-- 订单融资   未入库  已付款 -->
        <!-- <el-col 
          :span="5"
          class="cellMethod"
          v-if="orderItem.payStatus === 'F' && orderItem.arrivalStatus === 'F' && userType === 'seller'"
          >
          <el-button 
            type="danger"
            plain
            size="mini"
            @click="financingOrder(orderItem.orderId)">融资</el-button>
        </el-col>-->
      </el-row>
    </el-row>
    <!-- 确认订单 -->
    <el-dialog
    v-dialogDrag
      :title="dialogTitle"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="500px"
      :before-close="handleClose"
    >
      <el-form ref="dialogForm" :rules="rules" :model="dialogForm">
        <el-form-item label="短信验证码" prop="code">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-row :gutter="20">
                <el-col :span="16">
                  <el-input v-model="dialogForm.code" placeholder="短信验证码"></el-input>
                </el-col>
                <el-col :span="8">
                  <el-button
                    class="width-100"
                    :disabled="validBtnDisabled"
                    @click="sendValidNo()"
                  >{{ validButtonText }}</el-button>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="资金密码" prop="password">
          <el-input v-model="dialogForm.password" type="password" placeholder="资金密码"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" :loading="isConfirm" @click="confirmDialog()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 订单详情 -->
    <orderDetails ref="orderDetails" :userType="userType" />
    <!-- 补入库 -->
    <supplementaryDialog ref="supplementaryDialog" @reLoad="reLoadData()" />
    <!-- 议价是否同意 -->
    <el-dialog
    v-dialogDrag
      title="是否同意议价"
      :visible.sync="dialogVisible1"
      width="390px"
      :before-close="handleClose1"
    >
      <!-- <span></span> -->
      <el-select v-model="agreeOrRefuce" placeholder="请选择是否同意议价" style="width:100%;">
        <el-option
          v-for="item in AgreeOrRefuceOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose1">取 消</el-button>
        <el-button type="primary" @click="sellIsAgreeOrRefuce">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 订单融资弹窗 -->
    <addPledgeDialog ref="addPledgeDialog" />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import orderDetails from "./generalOrderDetails";
import md5 from "js-md5";
import supplementaryDialog from "../../sellerCenter/resourceMgt/warehousingApply/supplementaryDialog";
import addPledgeDialog from "../../sellerCenter/resourceMgt/preSellResourceOrder/addPledge";
export default {
  props: {
    orderItem: Object,
    userType: String,
    gzFlag: String,
    orderType: String,
    right: Object,
  },
  components: {
    orderDetails,
    supplementaryDialog,
    addPledgeDialog,
  },
  data() {
    return {
      fatherFrimId: sessionStorage.getItem("userFirmId"),
      timeOut: null,
      day: 0,
      hour: "00",
      min: "00",
      second: "00",
      // right: {
      //   confirmOrder: false,
      //   payEndMoney: false,
      //   openTicket: false,
      //   confirmOpenTicket: false,
      //   cancelOrder: false,
      //   bargainingHangOrder: false,
      //   applyWarehouse: false,
      //   bargainingHangOrders: false
      // },
      dialogVisible: false,
      dialogVisible1: false,
      validButtonText: "发送验证码",
      dialogForm: {
        code: null,
        password: null,
      },
      AgreeOrRefuceOptions: [
        {
          value: "T",
          label: "同意",
        },
        {
          value: "F",
          label: "拒绝",
        },
      ],
      agreeOrRefuce: null,
      currentpickResourcesId: null, // 议价时的id
      rules: {
        code: [
          { required: true, message: "请输入短信验证码", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入资金密码", trigger: "blur" },
        ],
      },
      isConfirm: false,
      isCancle: false,
      isEndMoney: false,
      validBtnDisabled: false,
      dialogOrderId: null,
      dialogTitle: null,
      dialogType: null, // 1: 确认订单  2: 付尾款
      isRongzi: window.g.isShowFinanceOrPledge,
    };
  },
  beforeDestroy() {
    if (this.timeOut) {
      clearInterval(this.timeOut);
      this.timeOut = null;
    }
  },
  mounted() {
    this.timeClick();
    // this.getRights();
  },
  computed: {
    ...mapGetters(["sessionInfoGetter", "getMarginRateGetter"]),
  },
  watch: {
    "orderItem.pickResourcesId": {
      handler(val, oldVal) {
        this.timeClick();
      },
    },
  },
  methods: {
    setTime(e, vueThis) {
      let _this = this;
      e--;
      vueThis.validButtonText = e + "s";
      if (e > 0) {
        setTimeout(function () {
          _this.setTime(e, vueThis);
        }, 1000);
      } else {
        vueThis.validButtonText = "发送验证码";
        vueThis.validBtnDisabled = false;
      }
    },
    sendValidNo() {
      protocol.param_authn_margin_sendCaptcha.param.id = 30;
      http
        .postFront(protocol.param_authn_margin_sendCaptcha)
        .then((response) => {
          this.$EL_MESSAGE(response.data.message);
          const that = this;
          this.validBtnDisabled = true;
          this.setTime(60, that);
        });
    },
    getRights() {
      this.loadRight().then((loadResult) => {
        if (loadResult == "loadRightSuccess") {
          this.right.confirmOrder = this.isHasRight(
            "tc-trade-confirmSellPickResource"
          ); // 确认摘单
          this.right.payEndMoney = this.isHasRight("tc-trade-payEndMoney"); // 买方付尾款
          this.right.openTicket = this.isHasRight("tc-trade-openTicket"); // 卖方开票
          this.right.confirmOpenTicket = this.isHasRight(
            "tc-trade-confirmTicket"
          ); // 买方确认开票
          this.right.cancelOrder = this.isHasRight("tc-trade-undoPickResource"); // 撤销摘单
          // this.right.bargainingHangOrder = this.isHasRight('trade-bargaining-hang-order'); // 议价
          this.right.bargainingHangOrder = this.isHasRight(
            "tc-trade-pickResourceBargain"
          ); //摘单方 议价
          this.right.bargainingHangOrders = this.isHasRight(
            "tc-trade-updatePickResourceBargainStatus"
          ); //挂单方 议价确认
          // this.right.applyWarehouse = this.isHasRight('trade-apply-warehouse'); // 补入库
        }
      });
    },
    timeClick() {
      if (this.orderItem && this.orderItem.qxTime) {
        this.countTime(this.orderItem.qxTime);
        this.timeOut = setInterval(() => {
          this.countTime(this.orderItem.qxTime);
          if (
            this.day == 0 &&
            this.hour == "00" &&
            this.min == "00" &&
            this.second == "00"
          ) {
            clearInterval(this.timeOut);
            this.timeOut = null;
          }
        }, 1000);
      }
    },
    getToday() {
      var date = new Date();
      var day = date.getTime();
      // var year = date.getFullYear();
      // var month = date.getMonth() + 1;
      // var day = date.getDate();
      // var hour = date.getHours();
      // var min = date.getMinutes();
      // var sec = date.getSeconds();
      // [year, month, day, hour, min, sec] = [year, month, day, hour, min, sec].map(
      //   item => (item < 10 ? "0" + item : item)
      // );
      return Number(`${day}`);
    },
    countTime(endPayTime) {
      // 获取当前时间
      const date = new Date();
      const now = date.getTime();
      // 设置截止时间
      // const endDate = new Date(endPayTime) // this.curStartTime需要倒计时的日期
      // const end = endDate.getTime()
      const end = Number(endPayTime);
      // 时间差
      const leftTime = end - now;
      let day = "";
      let hour = "";
      let min = "";
      let second = "";
      // 定义变量 d,h,m,s保存倒计时的时间
      if (leftTime >= 0) {
        // 天
        day = Math.floor(leftTime / 1000 / 60 / 60 / 24);
        // 时
        let h = Math.floor((leftTime / 1000 / 60 / 60) % 24);
        hour = h < 10 ? "0" + h : h;
        // 分
        let m = Math.floor((leftTime / 1000 / 60) % 60);
        min = m < 10 ? "0" + m : m;
        // 秒
        let s = Math.floor((leftTime / 1000) % 60);
        second = s < 10 ? "0" + s : s;
      } else {
        day = 0;
        hour = "00";
        min = "00";
        second = "00";
      }
      this.day = day;
      this.hour = hour;
      this.min = min;
      this.second = second;
    },
    showConfirmOrderDialog(orderItem) {
      let money = null;
      if (orderItem.type == "BO" || orderItem.type == "SO") {
        //  if(orderItem.pickBargainingStatus=='T'){
        // let calcMoney = Number(orderItem.bargainingPrice)* Number(orderItem.tradeWeight)*Number(this.getMarginRateGetter.marginRate)
        // money=  Number(calcMoney).toFixed(2)
        //  this.dialogTitle = "付首笔款: ￥"+money;
        // }else{
          if(this.userType == "seller"){
            this.dialogTitle = "确认订单";
          }else{
              let calcMoney =
              Number(orderItem.price) *
              Number(orderItem.tradeWeight) *
              Number(this.getMarginRateGetter.marginRate);
            money = Number(calcMoney).toFixed(2);
            this.dialogTitle = "付首笔款: ￥" + money;
          }
      
        // }
      } else if (this.userType == "buyer" && orderItem.type == "BA") {
        if (orderItem.pickBargainingStatus == "T") {
          let calcMoney = Number(
            Number(orderItem.bargainingPrice) * Number(orderItem.tradeWeight)
          ).toFixed(2);
          this.dialogTitle = "付全款: ￥" + calcMoney;
        } else {
          let calcMoney = Number(
            Number(orderItem.price) * Number(orderItem.tradeWeight)
          ).toFixed(2);
          this.dialogTitle = "付全款: ￥" + calcMoney;
        }
      } else {
        this.dialogTitle = "确认订单";
      }

      this.dialogOrderId = orderItem.pickResourcesId;
      this.dialogVisible = true;
      this.dialogType = 1;
    },
    showPayEndMoneyDialog(orderItem) {
      //议价状态为：T的，且type为BO的，bargainingPrice: 19*tradeWeight: 2*保证金比例   判断是否入库
      let money = null;
      if (orderItem.arrivalStatus == "F") {
        if (orderItem.pickBargainingStatus == "T") {
          // let calcMoney =
          //   Number(orderItem.price) *
          //   Number(orderItem.tradeWeight) *
          //   Number(this.getMarginRateGetter.marginRate);
          let realFunds =
            Number(orderItem.tradeWeight) * Number(orderItem.bargainingPrice);
          money = Number(Number(realFunds) - Number(orderItem.buyMargin)).toFixed(2);
        } else {
          // let calcMoney =
          //   Number(orderItem.price) *
          //   Number(orderItem.tradeWeight) *
          //   Number(this.getMarginRateGetter.marginRate);
          let realFunds =
            Number(orderItem.tradeWeight) * Number(orderItem.price);
          money = Number(Number(realFunds) - Number(orderItem.buyMargin)).toFixed(2);
        }
      } else {
        if (orderItem.pickBargainingStatus == "T") {
          // let calcMoney =
          //   Number(orderItem.price) *
          //   Number(orderItem.tradeWeight) *
          //   Number(this.getMarginRateGetter.marginRate);
          let realFunds =
            Number(orderItem.realWeight) * Number(orderItem.bargainingPrice);
          money = Number(Number(realFunds) - Number(orderItem.buyMargin)).toFixed(2);
        } else {
          // let calcMoney =
          //   Number(orderItem.price) *
          //   Number(orderItem.tradeWeight) *
          //   Number(this.getMarginRateGetter.marginRate);
          let realFunds =
            Number(orderItem.realWeight) * Number(orderItem.price);
          money = Number(Number(realFunds) - Number(orderItem.buyMargin)).toFixed(2);
        }
      }

      this.dialogTitle = "付剩余款： ￥" + money;
      this.dialogOrderId = orderItem.orderId;
      this.dialogVisible = true;
      this.dialogType = 2;
    },
    confirmDialog() {
      this.$refs["dialogForm"].validate((valid) => {
        if (valid) {
          if (this.dialogType == 1) {
            // 确认订单
            this.confirmOrder();
          } else if (this.dialogType == 2) {
            // 付尾款
            this.payEndMoney();
          }
        }
      });
    },
    confirmOrder() {
      this.$confirm("确定" + this.dialogTitle + "吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal:false
      })
        .then(() => {
          if (!this.isConfirm) {
            this.isConfirm = true;
          } else {
            return;
          }
          let newprotocolFwd = protocolFwd.param_confirmBuyPickResource;
          if (this.userType == "buyer") {
            newprotocolFwd = protocolFwd.param_confirmBuyPickResource;
          } else {
            newprotocolFwd = protocolFwd.param_confirmSellPickResource;
          }

          newprotocolFwd.param.pickResourcesId = this.dialogOrderId;
          newprotocolFwd.param.code = this.dialogForm.code;
          newprotocolFwd.param.marginPassWord = md5(
            "47DEQpj8HBSa+/TImW+5JCeuQeRkm5NMpJWZG3hSuFU=" +
              this.dialogForm.password
          );
          // console.log( newprotocolFwd)
          http.postFront(newprotocolFwd).then((response) => {
            const { code, message, value } = response.data;
            this.isConfirm = false;
            if (code == 0) {
              this.$EL_MESSAGE(message);
              this.$emit("reLoad");
              this.handleClose();
            } else {
              this.$EL_MESSAGE(message);
            }
          });
        })
        .catch(() => {
          this.isConfirm = false;
        });
    },
    cancelOrder(pickResourcesId) {
      this.$confirm("确定撤销该订单吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal:false
      })
        .then(() => {
          if (!this.isCancle) {
            this.isCancle = true;
          } else {
            return;
          }
          protocolFwd.param_undoPickResource.param.pickResourcesId = pickResourcesId;
          http
            .postFront(protocolFwd.param_undoPickResource)
            .then((response) => {
              const { code, message, value } = response.data;
              this.isCancle = false;
              if (code == 0) {
                this.$EL_MESSAGE(message);
                this.$emit("reLoad");
              } else {
                this.$EL_MESSAGE(message);
              }
            });
        })
        .catch(() => {
          this.isCancle = false;
        });
    },
    payEndMoney() {
      this.$confirm("确定" + this.dialogTitle + "吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal:false
      })
        .then(() => {
          if (!this.isEndMoney) {
            this.isEndMoney = true;
          } else {
            return;
          }
          protocolFwd.param_payEndMoney.param.orderId = this.dialogOrderId;
          protocolFwd.param_payEndMoney.param.code = this.dialogForm.code;
          protocolFwd.param_payEndMoney.param.marginPassWord = md5(
            "47DEQpj8HBSa+/TImW+5JCeuQeRkm5NMpJWZG3hSuFU=" +
              this.dialogForm.password
          );
          http.postFront(protocolFwd.param_payEndMoney).then((response) => {
            const { code, message, value } = response.data;
            this.isEndMoney = false;
            if (code == 0) {
              this.$EL_MESSAGE(message);
              this.$emit("reLoad");
              this.handleClose();
            } else {
              this.$EL_MESSAGE(message);
            }
          });
        })
        .catch(() => {
          this.isEndMoney = false;
        });
    },
    openTicket(orderId) {
      this.$confirm("确定开票吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal:false
      })
        .then(() => {
          protocolFwd.param_openTicket.param.orderId = orderId;
          http.postFront(protocolFwd.param_openTicket).then((response) => {
            const { code, message, value } = response.data;
            if (code == 0) {
              this.$EL_MESSAGE(message);
              this.$emit("reLoad");
            } else {
              this.$EL_MESSAGE(message);
            }
          });
        })
        .catch(() => {});
    },
    confirmOpenTicket(orderId) {
      this.$confirm("确定开票吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal:false
      })
        .then(() => {
          protocolFwd.param_confirmTicket.param.orderId = orderId;
          http.postFront(protocolFwd.param_confirmTicket).then((response) => {
            const { code, message, value } = response.data;
            if (code == 0) {
              this.$EL_MESSAGE(message);
              this.$emit("reLoad");
            } else {
              this.$EL_MESSAGE(message);
            }
          });
        })
        .catch(() => {});
    },
    bargainingHangOrder(orderId, pickResourcesId, hangFirmId,price) {
      if (hangFirmId != this.fatherFrimId && this.right.bargainingHangOrder) {
        this.$prompt("请输入议价单价", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          closeOnClickModal:false
        })
          .then(({ value }) => {
            let reg = /^\d+(\.\d{0,2})?$/;
            if (value == "") {
              return this.$EL_MESSAGE("请输入议价单价");
            } else if (!reg.test(value) || Number(value) <= 0) {
              return this.$EL_MESSAGE("议价单价必须是正数且不能超过两位小数");
            }else if(this.userType === 'seller'&&Number(value) <= price){
               return this.$EL_MESSAGE("议价单价必须高于原价");
            }else if(this.userType === 'buyer'&&Number(value) >= price){
              return this.$EL_MESSAGE("议价单价必须低于原价");
            }
            // protocolFwd.param_pickResourceBargain.param.orderId = orderId
            protocolFwd.param_pickResourceBargain.param.pickResourcesId = pickResourcesId;
            protocolFwd.param_pickResourceBargain.param.bargainPrice = value;
            http
              .postFront(protocolFwd.param_pickResourceBargain)
              .then((response) => {
                const { code, message, value } = response.data;
                if (code == 0) {
                  this.$EL_MESSAGE(message);
                  this.$emit("reLoad");
                } else {
                  this.$EL_MESSAGE(message);
                }
              });
          })
          .catch(() => {});
      } else {
        if (this.right.bargainingHangOrders) {
          this.dialogVisible1 = true;
          this.currentpickResourcesId = pickResourcesId;
          //    this.$prompt('请输入议价状态(同意T,拒绝F)', '提示', {
          //   confirmButtonText: '确定',
          //   cancelButtonText: '取消'
          // }).then(({ value }) => {
          //   if(value!='T'&&value!='F'){
          //     this.$EL_MESSAGE('如果同意请输入T，拒绝输入F');
          //   }
          //   protocolFwd.param_updatePickResourceBargainStatus.param.pickResourcesId = pickResourcesId
          //   protocolFwd.param_updatePickResourceBargainStatus.param.pickBargainingStatus = value
          //   http.postFront(protocolFwd.param_updatePickResourceBargainStatus).then(response => {
          //     const { code, message, value } = response.data;
          //     if (code == 0) {
          //       this.$EL_MESSAGE(message);
          //       this.$emit('reLoad');
          //     } else {
          //       this.$EL_MESSAGE(message);
          //     }
          //   });
          // }).catch(() => {
          // });
        } else {
          this.$EL_MESSAGE("暂无此权限！");
        }
      }
    },
    sellIsAgreeOrRefuce() {
      if (!this.agreeOrRefuce) {
        this.$EL_MESSAGE("请选择是否同意议价");
        return;
      }
      protocolFwd.param_updatePickResourceBargainStatus.param.pickResourcesId = this.currentpickResourcesId;
      protocolFwd.param_updatePickResourceBargainStatus.param.pickBargainingStatus = this.agreeOrRefuce;
      http
        .postFront(protocolFwd.param_updatePickResourceBargainStatus)
        .then((response) => {
          const { code, message, value } = response.data;
          if (code == 0) {
            this.$EL_MESSAGE(message);
            this.$emit("reLoad");
            this.handleClose1();
          } else {
            this.$EL_MESSAGE(message);
          }
        })
        .catch(() => {
          this.handleClose1();
        });
    },
    viewDetails(id, pickResourcesId,userType) {
      this.$refs.orderDetails.showDialog(id, pickResourcesId,userType);
    },
    goSupplementary(id) {
      this.$refs.supplementaryDialog.showDialog(id);
    },
    reLoadData() {
      this.$emit("reLoad");
    },
    handleClose() {
      this.dialogTitle = null;
      this.dialogOrderId = null;
      this.dialogVisible = false;
      this.validButtonText = '发送验证码'
      this.dialogType = null;
      this.dialogForm = {
        code: null,
        password: null,
      };
    },
    handleClose1() {
      this.dialogVisible1 = false;
      this.agreeOrRefuce = null;
      this.currentpickResourcesId = null;
    },
    appalyFinancing(orderId) {
      // const tempData = JSON.parse(JSON.stringify(row));
      this.$refs.addPledgeDialog.showDialog(orderId);
    },
  },
};
</script>
<style scoped lang="scss">
#generalOrderCell {
  line-height: 35px;
}
#generalOrderCell .generalOrderCellItem {
  text-align: center;
}
#generalOrderCell .cellOne {
  margin-top: 10px;
  border-top: 1px solid #ebeef5;
  border-left: 1px solid #ebeef5;
  border-right: 1px solid #ebeef5;
}
#generalOrderCell .cellTwo {
  border-top: 1px solid #ebeef5;
  border-left: 1px solid #ebeef5;
  border-right: 1px solid #ebeef5;
}
#generalOrderCell .cellThree {
  border-top: 1px solid #ebeef5;
  border-bottom: 1px solid #ebeef5;
  border-left: 1px solid #ebeef5;
  border-right: 1px solid #ebeef5;
}
.redColor {
  color: rgb(210, 29, 39);
}
.cellMethod {
  padding-right: 5px;
  float: right;
  text-align: right;
}
.viewDetailsButton {
  // margin-right: 30px;
  text-align: right;
}
.espli {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 10px;
  box-sizing: border-box;
}
</style>
