<template>
  <!-- <div>通用订单</div> -->
  <div id="generalOrder">
    <el-row>
      <el-row :gutter="10">
        <el-col :span="6">
          <el-col :span="7">
            <p class="searchItemTitle">订单编号</p>
          </el-col>
          <el-col :span="17">
            <el-input
              placeholder="请输入订单编号"
              v-model="searchForm.orderId"
            >
            </el-input>
          </el-col>
        </el-col>
        <el-col :span="8">
          <el-col :span="5">
            <p class="searchItemTitle">下单时间</p>
          </el-col>
          <el-col :span="19">
            <el-date-picker
              class="width-100" 
              v-model="searchForm.orderTime"
              type="daterange"
              range-separator="至"
              start-placeholder="下单开始日期"
              end-placeholder="下单结束日期"
              value-format="timestamp"
              >
            </el-date-picker>
          </el-col>
        </el-col>
        <el-col :span="6">
          <el-button type="danger" @click="getSearchDatas()">搜索</el-button>
          <el-button @click="resetSearch()">重置</el-button>
        </el-col>
      </el-row>
      <el-col :span="23">
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect" text-color="#000" active-text-color="rgb(210, 29, 39)">
          <el-menu-item index="1">全部</el-menu-item>
          <el-menu-item index="2">待付款</el-menu-item>
          <el-menu-item index="3">未开票</el-menu-item>
          <el-menu-item index="4">已开票</el-menu-item>
          <el-menu-item index="5">已完成</el-menu-item>
          <el-menu-item index="6">已取消</el-menu-item>
        </el-menu>
      </el-col>
      <el-col :span="23" class="darkColor">
        <el-col :span="22">
          <el-col :span="3"><p class="tableHeaderItem">商品分类</p></el-col>
          <el-col :span="4"><p class="tableHeaderItem">单价</p></el-col>
          <el-col :span="4"><p class="tableHeaderItem">仓库</p></el-col>
          <el-col :span="3"><p class="tableHeaderItem">入库状态</p></el-col>
          <el-col :span="3"><p class="tableHeaderItem">开票状态</p></el-col>
          <el-col :span="3"><p class="tableHeaderItem">付款状态</p></el-col>
          <el-col :span="4"><p class="tableHeaderItem">订单状态</p></el-col>
        </el-col>
      </el-col>
      <el-col :span="23" class="orderItem" v-for="(orderItem,index) in orders" :key="index">
        <general-order-cell :orderItem="orderItem" @reLoad="getSearchDatas" :userType="userType" :gzFlag="gzFlag" :orderType="orderType" :right="right"></general-order-cell>
      </el-col>
    </el-row>
    <div v-if="orders.length == 0" class="noData">
      <p>暂无数据</p>
    </div>
  </div>
</template>
<script>
import generalOrderCell from './generalOrderCell'
export default {
  props:{
    orders: Array,
    userType: String,
    gzFlag: String,
    orderType: String,
   
  },
  components:{
    'general-order-cell':generalOrderCell
  },
  data(){
    return{
      activeIndex: '1',
      searchForm: {
        orderId: null,
        startTime: null,
        endTime: null,
        orderTime: null,
        pickType: null,
        pickStatus: null
      },
        right: {
        confirmOrder: false,
        payEndMoney: false,
        openTicket: false,
        confirmOpenTicket: false,
        cancelOrder: false,
        bargainingHangOrder: false,
        applyWarehouse: false,
        bargainingHangOrders: false
      },
    }
  },
  mounted(){
    this.getRights()
  },
  methods:{
    resetSearch() {
      this.searchForm = {
        orderId: null,
        startTime: null,
        endTime: null,
        orderTime: null,
        orderStatus: null,
        payStatus: null,
        openTicketStatus: null,
        pickType: null,
        pickStatus: null
      }
      this.getSearchDatas();
    },
       getRights() {
      this.loadRight().then(loadResult => {
        if (loadResult == "loadRightSuccess") {
          this.right.confirmOrder = this.isHasRight(
            "tc-trade-confirmSellPickResource"
          ); // 确认摘单
          this.right.payEndMoney = this.isHasRight("tc-trade-payEndMoney"); // 买方付尾款
          this.right.openTicket = this.isHasRight("tc-trade-openTicket"); // 卖方开票
          this.right.confirmOpenTicket = this.isHasRight(
            "tc-trade-confirmTicket"
          ); // 买方确认开票
          this.right.cancelOrder = this.isHasRight("tc-trade-undoPickResource"); // 撤销摘单
          // this.right.bargainingHangOrder = this.isHasRight('trade-bargaining-hang-order'); // 议价
          this.right.bargainingHangOrder = this.isHasRight(
            "tc-trade-pickResourceBargain"
          ); //摘单方 议价
          this.right.bargainingHangOrders = this.isHasRight(
            "tc-trade-updatePickResourceBargainStatus"
          ); //挂单方 议价确认
          // this.right.applyWarehouse = this.isHasRight('trade-apply-warehouse'); // 补入库
        }
      });
    },
    // hangResourcesStatus  O：已挂单B：部分摘单A：全部摘单U：已撤销
    // tradeStatus 订单状态Z：已摘单T：已完成
    // pickResourcesStatus W：待确认（30分钟时效）T：已确认F：已失效U：已取消
   // 付首款和已取消用订单之前的状态   
   // orderStatus 订单状态订单状态O:已挂单Z：已摘单T：已完成U：已撤单 payStatus 支付状态付款状态W：待付款F：已付首款L：已付尾款 openTicketStatus开票状态 pickType摘单类型BO：买保证金摘BA：买全款摘SO：卖保证金摘SA：卖仓单摘 pickStatus
   // status 状态W：待确认（30分钟时效）T：已确认F：已失效U：已取消
   handleSelect(tab) {
      switch(tab) {
        case '1':
          // 全部
          this.searchForm.pickResourcesStatus = null
          this.searchForm.orderStatus = null
          this.searchForm.payStatus = null
          this.searchForm.openTicketStatus = null
          this.searchForm.pickType = null
          this.searchForm.pickStatus = null
          break;
        case '2':
          // 待付款
          this.searchForm.pickResourcesStatus = ['W']
          this.searchForm.orderStatus = ['Z','ZZ']
          this.searchForm.payStatus = ['W','ZZ']
          this.searchForm.openTicketStatus = null
          this.searchForm.pickType = null
          this.searchForm.pickStatus = ['W','T']
          break;
        case '3':
          // 未开票
          this.searchForm.pickResourcesStatus = null
          this.searchForm.orderStatus = ['Z']
          this.searchForm.payStatus = ['L']
          this.searchForm.openTicketStatus = ['W']
          this.searchForm.pickType = null
          this.searchForm.pickStatus = ['T']
          break;
        case '4':
          // 已开票
          this.searchForm.pickResourcesStatus = null
          this.searchForm.orderStatus = ['Z']
          this.searchForm.payStatus = ['L']
          this.searchForm.openTicketStatus = ['O']
          this.searchForm.pickType = null
          this.searchForm.pickStatus = ['T']
          break;
        case '5':
          // 已完成
          this.searchForm.pickResourcesStatus = null
          this.searchForm.orderStatus = ['T']
          this.searchForm.payStatus =  ['L']
          this.searchForm.openTicketStatus =  ['T']
          this.searchForm.pickType = null
          this.searchForm.pickStatus = ['T']
          break;
        case '6':
          // 已取消
          this.searchForm.pickResourcesStatus = ['F','U']
          this.searchForm.orderStatus = null
          this.searchForm.payStatus = null
          this.searchForm.openTicketStatus = null
          this.searchForm.pickType = null
          this.searchForm.pickStatus = ['F', 'U']
          break;
        
      }
      this.getSearchDatas();
    },
    getSearchDatas(){
      if (this.searchForm.orderTime && this.searchForm.orderTime.length !== 0) {
        this.searchForm.startTime = this.searchForm.orderTime[0];
        this.searchForm.endTime = this.searchForm.orderTime[1];
      }
      this.$emit('reLoad', this.searchForm);
    }
  }
}
</script>
<style scoped lang="scss">
.headerText{
  padding-left: 20px;
  font-size: 14px;
  line-height: 40px;
  color: #9999a2;
}
.darkColor{
  margin-top: 5px;
  padding: 5px 0;
  background: rgba(5, 64, 106, 1);
}
.tableHeaderItem{
  color: #fff;
  text-align: center;
  line-height: 30px;
}
.searchItemTitle {
  line-height: 40px;
}
</style>