<template>
  <el-dialog
  v-dialogDrag
    title="新增订单融资"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    width="800px"
  >
    <div>
      <el-form ref="form" :rules="rules" :model="form" label-position="top">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="融资方名称" prop="factorId">
              <el-select class="width-100" v-model="form.factorId" @change="getRateInfo">
                <el-option
                  v-for="(item,index) in factorIdOptions"
                  :key="index"
                  :label="item.factorName"
                  :value="Number(item.factorId)"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="还款期限" prop="paybackTimeId">
              <el-select class="width-100" v-model="form.paybackTimeId">
                <el-option
                  v-for="(item,index) in payBackTimeOptions"
                  :key="index"
                  :label="item.timeLimit+''+'天'"
                  :value="item.paybackTimeId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="融资利息比例/日">
              <el-input :disabled="true" v-model="details.feeRate"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="回款逾期费比例/日">
              <el-input :disabled="true" v-model="details.lateFeeRate"></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="规格">
              <el-input :disabled="true" v-model="details.specifications"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="包装规格">
              <el-input :disabled="true" v-model="details.specificationsName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="仓库">
              <el-input :disabled="true" v-model="details.name"></el-input>
            </el-form-item>
          </el-col> --> 
        </el-row>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="confirm()" :loading="isAddLoading">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      dialogVisible: false,
      form: {
        factorId: null,
        paybackTimeId: null,
        orderId: null,
      },
      details: {},
      payBackTimeOptions:[],
      rules: {
        factorId: [
          { required: true, message: "请选择融资方名称", trigger: "change" }
        ],
        paybackTimeId: [{ required: true, message: "请选择还款期限", trigger: "change" }],
        warehousId: [{ required: true, message: "请选择融资仓单", trigger: "change" }],
      },
      factorIdOptions: [],
      isAddLoading: false,
    };
  },
  computed: {
    ...mapGetters(["sessionInfoGetter"])
  },
  watch:{
  //   "form.factorId":{
  //    handler(val){
  //     this.form.paybackTimeId = null
  //     this.details = {}
  //    }
  //  },
   "form.paybackTimeId":{
     handler(val){
       let that = this
       this.payBackTimeOptions.forEach(element =>{
         if(val == element.paybackTimeId){
           that.details = element
         }
       })
     }
   }
  },
  mounted(){
    // this.getWarehousIdOptions();
  },
  methods: {
    showDialog(orderId) {
      this.dialogVisible = true;
      this.form.orderId = orderId
      this.getWarehousIdOptions();
    },
    //查询所有资方信息
    getWarehousIdOptions() {
      http.postFront(protocolFwd.param_findFactorAll).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.factorIdOptions = value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    //融资方信息比例查询
    getRateInfo() {
      this.form.paybackTimeId = null
      this.details = {}
      this.payBackTimeOptions = []
      protocolFwd.param_findFinanceRateInfo.param.factorId = Number(this.form.factorId);
      http
        .postFront(protocolFwd.param_findFinanceRateInfo)
        .then(response => {
          const { code, message, value } = response.data;
          if (code == 0) {
            this.payBackTimeOptions = value
            console.log(this.payBackTimeOptions)
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    },
    //融资订单申请
    confirm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          protocolFwd.param_orderApply.param = this.form;
          protocolFwd.param_orderApply.param.firmId = Number(this.sessionInfoGetter.firmId);
          this.isAddLoading = true;
          http.postFront(protocolFwd.param_orderApply).then(response => {
              const { code, message, value } = response.data;
              this.isAddLoading = false;
              if (code == 0) {
                this.dialogVisible = false;
                this.$EL_MESSAGE("提交成功！");
                this.$refs["form"].resetFields();
                this.$emit("reLoad");
                this.handleClose()
              } else {
                this.$EL_MESSAGE(message);
              }
            })
            .catch(() => {
              this.isAddLoading = false;
            });
        }
      });
    },
    handleClose() {
      this.form = {
        factorId: null,
        paybackTimeId: null,
        orderId: null,
      };
      this.details = {}
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
      this.isFromWarehouse = false;
      this.dialogVisible = false;
    }
  }
};
</script>