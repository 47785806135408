<template>
  <el-dialog
  v-dialogDrag
    title="订单详情"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    width="900px"
  >
    <div class="viewDetails">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">订单编号：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.orderId }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12" v-if="userType=='seller'">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">持仓ID：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.sellHoldId }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">下单时间：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.createTime | DateFormate }}</p>
            </el-col>
          </el-row>
        </el-col>
    
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">商品分类：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.className }}</p>
            </el-col>
          </el-row>
        </el-col>
         <el-col :span="12" v-for="(item,index) of form.specificationsName" :key="index">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">商品属性：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ item.label }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">开票期限：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.openTicketTime | DateFormateDay }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">订单状态：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.tradeStatus | ordersStatusFormate }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">发布单价：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.price }}元/{{form.unit}}</p>
            </el-col>
          </el-row>
        </el-col> 
        <el-col :span="12" v-if="form.pickBargainingStatus!='N'">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">议价状态：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.pickBargainingStatus|priceStatusFormate }}</p>
            </el-col>
          </el-row>
        </el-col> 
        <el-col :span="12" v-if="form.pickBargainingStatus!='N'">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">议价单价：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.bargainingPrice }}元/{{form.unit}}</p>
            </el-col>
          </el-row>
        </el-col> 
        <!-- <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">数量：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.quantity }}</p>
            </el-col>
          </el-row>
        </el-col> -->
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">摘单重量：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.tradeWeight }}{{form.unit}}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">已入库重量：</p>
            </el-col>
            <el-col :span="16" v-if="form.pickResourcesStatus=='F'||form.pickResourcesStatus=='U'||form.pickResourcesStatus=='ZZ'||form.pickResourcesStatus=='W'">
              <p class="text">{{ form.realWeight }} {{form.unit}}</p>
            </el-col>
            <el-col :span="16" v-else>
              <p class="text">{{ form.realWeight!='--'?form.realWeight:'0' }}{{form.unit}}</p>
            </el-col>
          </el-row>
        </el-col>
         <el-col :span="12" v-if="form.arrivalStatus == 'F'">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">预计入库时间：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.putWarehousTime | DateFormateDay }}</p>
            </el-col>
          </el-row>
        </el-col>
         <el-col :span="12"  v-if="form.arrivalStatus == 'T'||form.arrivalStatus == 'B'">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">入库时间：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.fillPutWarehousTime | DateFormateDay }}</p>
            </el-col>
          </el-row>
        </el-col>
        <!-- <el-col :span="12" v-if="form.hangResourceType!='Q'">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">生产厂家：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.manufacturer }}</p>
            </el-col>
          </el-row>
        </el-col> -->
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">交收仓库：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.sname }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">总价格：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.funds }}元</p>
              <!-- <p v-if="orderType != 'bidding'" class="text">{{ form.zjPrice }}元</p>
              <p v-if="orderType == 'bidding'" class="text">{{ form.offerPrice }}元</p> -->
            </el-col>
          </el-row>
        </el-col>
         <el-col :span="12" v-if="form.arrivalStatus == 'B'||form.arrivalStatus == 'T'">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">成交总价：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.arrivalFunds }}元</p>
             
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">卖方信息：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.sellEnterpriseName }}</p>
            </el-col>
          </el-row>
        </el-col>
     
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">买方信息：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.buyEnterpriseName }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">入库状态：</p>
            </el-col>
            <el-col :span="16">
              <p v-if="form.pickResourcesStatus !== 'F' && form.pickResourcesStatus !== 'U'" class="text">{{ form.arrivalStatus | arrivalStatusFormate }}</p>
              <p v-else>--</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">开票状态：</p>
            </el-col>
            <el-col :span="16">
              <p v-if="form.pickResourcesStatus !== 'F' && form.pickResourcesStatus !== 'U'" class="text">{{ form.openTicketStatus | ballotStatusFormate }}</p>
              <p v-else>--</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">付款状态：</p>
            </el-col>
            <el-col :span="16">
              <p v-if="form.pickResourcesStatus !== 'F' && form.pickResourcesStatus !== 'U'" class="text">{{ form.payStatus | payStatusFormate }}</p>
              <p v-else>--</p>
            </el-col>
          </el-row>
        </el-col>
     
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">买方违约状态：</p>
            </el-col>
            <el-col :span="16">
              <p v-if="form.pickResourcesStatus !== 'F' && form.pickResourcesStatus !== 'U'" class="text">{{ form.defaultBuyStatus | buyersDefaultStatusFormate }}</p>
              <p v-else>--</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">卖方违约状态：</p>
            </el-col>
            <el-col :span="16">
              <p v-if="form.pickResourcesStatus !== 'F' && form.pickResourcesStatus !== 'U'" class="text">{{ form.defaultSellStatus | sellersDefaultStatusFormate }}</p>
              <p v-else>--</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">挂单方联系人：</p>
            </el-col>
            <el-col :span="16">
              <p v-if="form.pickResourcesStatus !== 'F' && form.pickResourcesStatus !== 'U'" class="text">{{ form.businessName }}</p>
              <p v-else>--</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">挂单方联系方式：</p>
            </el-col>
            <el-col :span="16">
              <p v-if="form.pickResourcesStatus !== 'F' && form.pickResourcesStatus !== 'U'" class="text">{{ form.businessPhone }}</p>
              <p v-else>--</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">摘单方联系人：</p>
            </el-col>
            <el-col :span="16">
              <p v-if="form.pickResourcesStatus !== 'F' && form.pickResourcesStatus !== 'U'" class="text">{{ form.pickBusinessContact }}</p>
              <p v-else>--</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">摘单方联系方式：</p>
            </el-col>
            <el-col :span="16">
              <p v-if="form.pickResourcesStatus !== 'F' && form.pickResourcesStatus !== 'U'" class="text">{{ form.pickBusinessPhone }}</p>
              <p v-else>--</p>
            </el-col>
          </el-row>
        </el-col>
        <!-- <el-col :span="12" v-if="form.hangResourceType=='Q'">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">特殊要求描述：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.manufacturer }}</p>
            </el-col>
          </el-row>
        </el-col> -->
      </el-row>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  props:{
    userType: String
  },
  data() {
    return {
      dialogVisible: false,
      form: {
        // warehousId: null,
        // price: null
      }
    };
  },
  methods: {
    showDialog(id,pickResourcesId) {
      this.dialogVisible = true;
      this.getDetails(id,pickResourcesId);
    },
    getDetails(id,pickResourcesId) {
      protocolFwd.param_queryFirmOrder.param.pickResourcesId= pickResourcesId;
      protocolFwd.param_queryFirmOrder.param.size= 1;
      protocolFwd.param_queryFirmOrder.param.page= 0;
      protocolFwd.param_queryFirmOrder.param.orderId= null;
      protocolFwd.param_queryFirmOrder.param.arrivalStatus=null;
      protocolFwd.param_queryFirmOrder.param.openTicketStatus= null;
      protocolFwd.param_queryFirmOrder.param.payStatus= null;
      protocolFwd.param_queryFirmOrder.param.startTime= null;
      protocolFwd.param_queryFirmOrder.param.endTime= null;
      protocolFwd.param_queryFirmOrder.param.tradeStatus= null;
      http
        .postFront(protocolFwd.param_queryFirmOrder)
        .then(response => {
          const { code, message, value } = response.data;
          if (code == 0) {
            value.content[0].specificationsName = this.attributeTrans(value.content[0].specificationsName)
            if(value.content[0].arrivalStatus=='B'){
              if(value.content[0].pickBargainingStatus=='T'){
                 value.content[0].arrivalFunds=util.floatMul(value.content[0].bargainingPrice,value.content[0].realWeight)
              }else{
                 value.content[0].arrivalFunds =util.floatMul(value.content[0].price,value.content[0].realWeight)
              }
             
            }
               this.form = value.content[0];
            for (let i in this.form) {
              if (!this.form[i]&&this.form[i]!=0) {
                this.form[i] = "--";
              }
            }
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    },
    handleClose() {
      this.dialogVisible = false;
    }
  }
};
</script>
<style scoped>
.viewDetails {
  line-height: 40px;
}
.viewDetails .title {
  text-align: right;
}
.viewDetails .text {
  text-align: left;
}
.proPictureImg {
  max-width: 80%;
}
</style>